<template>
  <div class="d-flex flex-column h100">
    <div class="wrapper-alert" v-if="alert">
      <div class="black-alert">
        <v-icon>mdi-check</v-icon> Изменения сохранены
      </div>
    </div>
    <div class="pb flex-grow-1">
      <div class="mb-3">
        <div class="section">{{ step1Name }}</div>
        <design-carousel
          style="margin-left: -33px; margin-right: -33px"
          class="design-carousel"
          @change-certificate="form.certificate = $event"
        />
      </div>
      <div>
        <div class="section">{{ step2Name }}</div>
        <div class="d-flex flex-row flex-wrap">
          <Sub
            @change-sub="selectedSub = $event"
            v-for="(item, index) in subs"
            :key="index"
            :item="item"
            class="mr-2 mb-2"
            :active="item.Id === selectedSubId"
          />
        </div>
      </div>
      <div class="mb-9">
        <div>
          <div class="section">{{ step3Name }}</div>
          <MlTextarea
            v-model="form.congratulation"
            placeholder="Не забудьте написать несколько приятных слов получателю сертификата"
          />

          <div
            class="d-flex flex-row align-center justify-content-between pt-1"
          >
            <a href="#" @click.prevent="openPreview" v-if="hasPreviewRule">
              <img src="~@/assets/img/eye.png" v-if="!isAllowContinue" alt="" />
              <img src="~@/assets/img/eye-grey.svg" v-else alt="" />
              <span
                class="ml-text-14-20-500 ml-2 "
                :class="{ 'ml-text-grey2': isAllowContinue }"
                >Предпросмотр</span
              >
            </a>
            <a
              href="#"
              @click.prevent="setFriend"
              class="ml-silver-btn-sm ml-py-2px"
              v-if="isShowFriendBtn"
            >
              {{ friendName }}
            </a>
          </div>
        </div>
        <div class="mt-4 mb-6" v-if="isUpdate && currentCertificate">
          <MlNumeric2 max="10" v-model="form.count" @input="changeCount" />
        </div>
      </div>
    </div>
    <div class="controlls" v-if="!isUpdate" ref="controlls">
      <button
        :disabled="isAllowContinue"
        @click.prevent="nextPage"
        class="ml-black-btn"
        style="width: 100%"
      >
        {{ titleNextBtn }}
      </button>
    </div>
    <div class="controlls" v-if="isUpdate" ref="controlls">
      <button @click.prevent="save" class="ml-black-btn" style="width: 100%">
        Сохранить изменения
      </button>
    </div>
  </div>
</template>

<script>
import Sub from '@/components/Panel/Sub'

// import designCarousel from '@/components/Panel/DesignCarousel'
// import MlTextarea from '@/components/UI/MlTextarea'
// import MlNumeric2 from '@/components/UI/MlNumeric2'
// import MixinChangePanelPage from '@/helpers/mixins/panel/changePage'
// import MixinObserveElement from '@/helpers/mixins/observeElement'
import MixinOrder from '@/helpers/mixins/order/order'

export default {
  // mixins: [MixinChangePanelPage, MixinObserveElement, MixinOrder],
  mixins: [MixinOrder],
  components: {
    //   designCarousel,
    Sub
    //   MlTextarea,
    //   MlNumeric2
  },
  data: () => ({
    selectedSub: null
    // alert: false,
    // form: {
    //   id: null,
    //   certificate: null,
    //   congratulation: null,
    //   count: null
    // }
  }),
  computed: {
    // ...mapState({
    //   // currentCertificate: state => state.basket.currentCertificate,
    //   // preview: state => state.basket.preview,
    //   // orderConfig: state => state?.app?.pageOrderConfig
    //   // friend: state => state?.order?.friend
    // }),
    // ...mapGetters(['verificationCode/isVerified', 'basket/allPositions']),
    // ...mapGetters('app', ['hasPreviewRule']),
    // friendName() {
    //   const name = this.friend?.name
    //   if (name) {
    //     return name
    //   }
    //   return 'Контакты получателя'
    // },
    orderPrice() {
      return this.selectedSub?.Price
    },
    selectedSubId() {
      return this.selectedSub?.Id
    },
    // observedElement() {
    //   return this.$refs.controlls
    // },
    // designsSubscription() {
    //   return this.orderConfig?.Design
    // },
    // step1Name() {
    //   return (
    //     this.orderConfig?.Step1_Name || Constants?.DEFAULTS?.ORDER?.STEP1_NAME
    //   )
    // },
    // step2Name() {
    //   return (
    //     this.orderConfig?.Step2_Name || Constants?.DEFAULTS?.ORDER?.STEP2_NAME
    //   )
    // },
    // step3Name() {
    //   return (
    //     this.orderConfig?.Step3_Name || Constants?.DEFAULTS?.ORDER?.STEP3_NAME
    //   )
    // },
    // minFreeNominal() {
    //   return this.orderConfig?.MinFreeNominal
    // },
    // maxFreeNominal() {
    //   return this.orderConfig?.MaxFreeNominal
    // },
    subs() {
      return this.orderConfig?.Goods
    }
    // isUpdate() {
    //   return this.currentCertificate ? true : false
    // },
    // isPreview() {
    //   return this.preview ? true : false
    // }
    // isAllowContinue() {
    //   return !this.form.certificate || !this.selectedSub
    // },
    // titleNextBtn() {
    //   const count = this['basket/allPositions']?.count
    //   return count > 0 ? 'Добавить в корзину' : 'Продолжить'
    // }
  },
  // mounted() {
  //   this.setInitialize()
  // },
  methods: {
    // ...mapMutations('basket', [
    //   // basketTypes.CALL_CONFIRM_MODAL,
    //   // basketTypes.SET_PREVIEW
    // ]),
    // ...mapMutations('app', [appTypes.SET_OPACITY]),
    // ...mapMutations('order', [orderTypes.SET_FRIEND]),
    // ...mapActions('app', [appTypes.GET_ORDER_PAGE_CONFIG]),
    // ...mapActions('basket', [
    //   basketTypes.ADD_CERTIFICATE_ACTION,
    //   basketTypes.UPDATE_CERTIFICATE_ACTION
    // ]),
    // setFriend() {
    //   const item = {
    //     certificate: this.form?.certificate,
    //     price: this.selectedSub?.Price,
    //     congratulation: this.form?.congratulation,
    //     friend: this.friend
    //   }
    //   this[basketTypes.SET_PREVIEW](item)
    //   this.$router.push({
    //     name: 'FriendContact',
    //     params: { routeAfterSave: 'Order' }
    //   })
    // },
    // async setInitialize() {
    //   if (this.isUpdate || this.isPreview) {
    //     this.loadCertificateFromStore()
    //   }
    //   // await this[appTypes.GET_ORDER_PAGE_CONFIG]()
    //   this.handleScroll()
    // },
    // makeBasketItem() {
    //   let item = this.form
    //   item.price = this.selectedSub?.Price
    //   return item
    // },
    // openPreview() {
    //   if (this.isAllowContinue) return
    //   const item = {
    //     certificate: this.form?.certificate,
    //     price: this.selectedSub?.Price,
    //     congratulation: this.form?.congratulation,
    //     friend: this.friend
    //   }
    //   this[basketTypes.SET_PREVIEW](item)
    //   this[appTypes.SET_OPACITY](0)
    //   setTimeout(() => {
    //     this.$router.push('/preview-mobile')
    //   }, 250)
    // },
    // save() {
    //   let data = this.makeBasketItem()
    //   this[basketTypes.UPDATE_CERTIFICATE_ACTION](data)
    //   this.alert = true
    //   this[basketTypes.SET_PREVIEW](null)
    //   setTimeout(() => {
    //     this.$router.push('/basket')
    //   }, 1500)
    // },
    // changeCount(newValue) {
    //   this.form.count = newValue
    //   const data = this.makeBasketItem()
    //   this[basketTypes.UPDATE_CERTIFICATE_ACTION](data)
    // },
    // removeCertificate() {
    //   this[basketTypes.CALL_CONFIRM_MODAL](this.currentCertificate)
    // },
    loadPrice(price) {
      const findSub = this.subs?.find(sub => sub?.Price === price)
      if (findSub) {
        this.selectedSub = findSub
      }
    }
    // loadCertificateFromStore() {
    //   let price = 0
    //   if (this.currentCertificate) {
    //     this.form.id = this.currentCertificate.id
    //     this.form.congratulation = this.currentCertificate?.congratulation
    //     this.form.certificate = this.currentCertificate?.certificate
    //     this.form.count = this.currentCertificate?.count
    //     this[orderTypes.SET_FRIEND](this.currentCertificate?.friend)
    //     price = this.currentCertificate?.price
    //   }
    //   if (this.preview) {
    //     this.form.congratulation = this.preview?.congratulation
    //     this.form.certificate = this.preview?.certificate
    //     this[orderTypes.SET_FRIEND](this.preview?.friend)
    //     price = this.preview?.price
    //   }
    //   if (price) {
    //     this.loadPrice()
    //   }
    // },
    // storeCertificate() {
    //   let certificate = this.form
    //   certificate.price = this.selectedSub?.Price
    //   certificate.count = 1
    //   certificate.friend = cloneDeep(this.friend)
    //   console.log(certificate)
    //   this[basketTypes.ADD_CERTIFICATE_ACTION](certificate)
    // },
    // changeSub(value) {
    //   this.selectedSub = value
    // },
    // nextPage() {
    //   this.storeCertificate()
    //   this[basketTypes.SET_PREVIEW](null)
    //   this[orderTypes.SET_FRIEND](null)
    //   this[appTypes.SET_OPACITY](0)

    //   setTimeout(() => {
    //     if (this['verificationCode/isVerified']) {
    //       this.$router.push('/confirming')
    //     } else {
    //       this.$router.push('/sending')
    //     }
    //   }, 250)
    // }
  }
}
</script>
