import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

import designCarousel from '@/components/Panel/DesignCarousel'
import MlTextarea from '@/components/UI/MlTextarea'
import MlNumeric2 from '@/components/UI/MlNumeric2'
import MixinChangePanelPage from '@/helpers/mixins/panel/changePage'
import MixinObserveElement from '@/helpers/mixins/observeElement'
import MixinCommon from '@/helpers/mixins/common'
// import MixinOrder from '@/helpers/mixins/order/order'
import Constants from '@/helpers/constants'
import appTypes from '@/store/app/types'
import basketTypes from '@/store/basket/types'
import orderTypes from '@/store/order/types'
import { cloneDeep } from 'lodash'

export default {
  mixins: [MixinChangePanelPage, MixinObserveElement, MixinCommon],
  components: {
    designCarousel,
    // Sub,
    MlTextarea,
    MlNumeric2
  },
  data: () => ({
    alert: false,
    form: {
      id: null,
      certificate: null,
      congratulation: null,
      count: null
    }
  }),
  computed: {
    ...mapState({
      currentCertificate: state => state.basket.currentCertificate,
      preview: state => state.basket.preview,
      orderConfig: state => state?.app?.pageOrderConfig,
      friend: state => state?.order?.friend
    }),
    ...mapGetters(['verificationCode/isVerified', 'basket/allPositions']),
    ...mapGetters('app', ['hasPreviewRule']),
    isShowFriendBtn() {
      return this.orderConfig?.ItemSettings
    },
    isUpdate() {
      return this.currentCertificate ? true : false
    },
    isPreview() {
      return this.preview ? true : false
    },
    friendName() {
      // const name = this.friend?.name
      // if (name) {
      // return name
      // }
      return this.friend?.name || 'Контакты получателя'
    },
    isAllowContinue() {
      return !this.form.certificate || !this.orderPrice
    },
    observedElement() {
      return this.$refs.controlls
    },
    step1Name() {
      return (
        this.orderConfig?.Step1_Name || Constants?.DEFAULTS?.ORDER?.STEP1_NAME
      )
    },
    step2Name() {
      return (
        this.orderConfig?.Step2_Name || Constants?.DEFAULTS?.ORDER?.STEP2_NAME
      )
    },
    step3Name() {
      return (
        this.orderConfig?.Step3_Name || Constants?.DEFAULTS?.ORDER?.STEP3_NAME
      )
    },
    titleNextBtn() {
      const count = this['basket/allPositions']?.count
      return count > 0 ? 'Добавить в корзину' : 'Продолжить'
    }
  },
  mounted() {
    this.setInitialize()
  },
  methods: {
    ...mapMutations('basket', [basketTypes.SET_PREVIEW]),
    // ...mapMutations('app', [appTypes.SET_OPACITY]),
    ...mapMutations('order', [orderTypes.SET_FRIEND]),
    ...mapActions('basket', [
      basketTypes.ADD_CERTIFICATE_ACTION,
      basketTypes.UPDATE_CERTIFICATE_ACTION
    ]),
    nextPage() {
      this[basketTypes.SET_PREVIEW](null)
      this[orderTypes.SET_FRIEND](null)
      this.showNavsAndRedirect(false, {
        name: this['verificationCode/isVerified'] ? 'Confirming' : 'Contacts'
      })
      setTimeout(() => {
        this.storeCertificate()
      }, 500)
    },
    changeCount(newValue) {
      this.form.count = newValue
      const data = this.makeBasketItem()
      this[basketTypes.UPDATE_CERTIFICATE_ACTION](data)
    },
    save() {
      let data = this.makeBasketItem()
      this[basketTypes.UPDATE_CERTIFICATE_ACTION](data)
      this.alert = true
      this[basketTypes.SET_PREVIEW](null)
      setTimeout(() => {
        this.$router.push('/basket')
      }, 250)
    },
    loadCertificateFromStore() {
      let price = 0
      if (this.currentCertificate) {
        this.form.id = this.currentCertificate.id
        this.form.congratulation = this.currentCertificate?.congratulation
        this.form.certificate = this.currentCertificate?.certificate
        this.form.count = this.currentCertificate?.count
        this[orderTypes.SET_FRIEND](this.currentCertificate?.friend)
        price = this.currentCertificate?.price
      }
      if (this.preview) {
        this.form.congratulation = this.preview?.congratulation
        this.form.certificate = this.preview?.certificate
        this[orderTypes.SET_FRIEND](this.preview?.friend)
        price = this.preview?.price
      }
      if (price) {
        this.loadPrice(price)
      }
    },
    storeCertificate() {
      let certificate = this.form
      certificate.price = this.orderPrice
      certificate.count = 1
      certificate.friend = cloneDeep(this.friend)
      this[basketTypes.ADD_CERTIFICATE_ACTION](certificate)
    },
    makeBasketItem() {
      let item = this.form
      item.price = this.orderPrice
      item.friend = cloneDeep(this.friend)
      return item
    },
    setInitialize() {
      if (this.isUpdate || this.isPreview) {
        this.loadCertificateFromStore()
      }
      this.showNavs(true)
      this.$nextTick(() => {
        // window.scrollTo({
        //   top: window.innerHeight,
        //   left: 0,
        //   behavior: 'smooth'
        // })
        this.handleScroll()
      })
    },
    openPreview() {
      if (this.isAllowContinue) return
      const item = {
        certificate: this.form?.certificate,
        price: this.orderPrice,
        congratulation: this.form?.congratulation,
        friend: this.friend
      }
      this[basketTypes.SET_PREVIEW](item)
      // this[appTypes.SET_OPACITY](0)
      this.showNavs(false)
      setTimeout(() => {
        this.$router.push('/preview-mobile')
      }, 200)
    },
    setFriend() {
      const item = {
        certificate: this.form?.certificate,
        price: this.orderPrice,
        congratulation: this.form?.congratulation,
        friend: this.friend
      }
      this[basketTypes.SET_PREVIEW](item)
      this.showNavsAndRedirect(false, {
        name: 'FriendContact',
        params: { routeAfterSave: 'Order' }
      })
    }
  }
}
