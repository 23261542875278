<template>
  <div class="par" @click="selectPar" :class="{ active }">
    <input
      @blur="change"
      style="width: 61px"
      :min="min"
      :max="max"
      type="number"
      placeholder="Указать"
      :value="value"
      v-if="!readonly"
    />
    <span v-else>{{ value }}</span>
    <span class="ml-1">₽</span>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    min: {
      type: Number,
      required: false,
      default: null
    },
    max: {
      type: Number,
      required: false,
      default: null
    },
    value: {
      type: Number,
      required: false,
      default: null
    },
    readonly: {
      type: Boolean,
      required: false,
      default: true
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  directives: {
    mask
  },
  methods: {
    change(event) {
      let value = Number(event.target.value)
      if (this.min && value < this.min) {
        value = this.min
      } else if (this.max && value > this.max) {
        value = this.max
      }
      this.$emit('input', Number(value))
      this.$forceUpdate()
    },
    selectPar() {
      if (this.readonly) {
        this.$emit('change-par', this.value)
      }
    }
  }
}
</script>
