import appTypes from '@/store/app/types'
import { mapMutations } from 'vuex'
export default {
  methods: {
    ...mapMutations('app', [appTypes.SET_OPACITY]),
    /**
     * Прячет/показывает кнопки бургера и корзины
     * @param {Boolean} isShow
     */
    showNavs(isShow = false) {
      this[appTypes.SET_OPACITY](isShow === false ? 0 : 1)
    },
    /**
     * Прячет/показывает кнопки бургера и корзины, а затем делает переход по url
     * @param {Boolean} isShow
     * @param {Object} route
     */
    showNavsAndRedirect(isShow = false, route) {
      this[appTypes.SET_OPACITY](isShow === false ? 0 : 1)
      setTimeout(() => {
        this.$router.push(route)
      }, 250)
    }
  }
}
