<template>
  <div v-if="options.certificates.length">
    <swiper
      @slideChange="slideChange"
      class="mloyalty-swiper swiper"
      ref="swiper-cert"
      :options="swiperOption"
    >
      <swiper-slide
        class="mloyalty-swiper-slide"
        v-for="(item, i) in options.certificates"
        :key="i"
      >
        <div class="mloyalty-swiper-slide-wrapper">
          <div class="overlay"></div>
          <img :src="item.img" alt="" height="200" />
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
const swiperOption = {
  // touchStartPreventDefault: false,
  // grabCursor: true,
  // touchReleaseOnEdges: true,
  followFinger: false,
  allowTouchMove: true,
  updateOnWindowResize: false,
  slidesOffsetBefore: 35,
  autoHeight: true,
  slidesPerView: 'auto',
  // slidesPerGroup: 1,
  loop: true,
  // centeredSlides: true,
  spaceBetween: 8,
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  }
}

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'

// import style (<= Swiper 5.x)
// import 'swiper/css/swiper.css'
import 'swiper/swiper.scss'

import { Swiper as SwiperClass, Pagination } from 'swiper/swiper.esm'
// import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
SwiperClass.use([Pagination])
// Vue.use(getAwesomeSwiper(SwiperClass))

import { mapState } from 'vuex'
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data: () => ({
    loading: true
  }),
  destroyed() {
    const el = document.getElementsByClassName('swiper-container')[0]
    el?.removeEventListener('wheel', this.handleScroll)
  },
  methods: {
    handleScroll(event) {
      event.preventDefault()
      const { deltaY } = event
      if (deltaY > 0) {
        this.swiper.slideNext()
      } else if (deltaY < 0) {
        this.swiper.slidePrev()
      }
    },
    init() {
      const el = document.getElementsByClassName('swiper-container')[0]
      el?.addEventListener('wheel', this.handleScroll, true)

      const func = id => {
        const findCertificate = this.options?.certificates.find(
          x => x.id === id
        )
        const swiper = this.$refs['swiper-cert'].$swiper
        if (findCertificate) {
          const index = this.options.certificates.indexOf(findCertificate)
          if (index === 0) {
            swiper.slideTo(index + this.countCertificates, 500, false)
          } else {
            swiper.slideTo(index, 500, false)
          }
          this.$emit('change-certificate', findCertificate)
        }
      }
      if (this.currentCertificate) {
        func(this.currentCertificate?.certificate?.id)
      } else if (this.preview) {
        func(this.preview?.certificate?.id)
      } else {
        this.$emit('change-certificate', this.options.certificates[0])
      }
      // setTimeout(() => {
      //   this.offsetSlide(false)
      // }, 250)
    },
    slideChange(item) {
      const count = this.countCertificates
      if (!count) return
      const index = item.activeIndex
      let diff = index - count
      if (diff === count) {
        diff = 0
      } else if (diff < 0) {
        diff = index
      }
      const cert = this.options.certificates[diff]
      this.$emit('change-certificate', cert)
    },
    /** Смещение слайдера как в дизайне */
    offsetSlide(isLoading = null) {
      return
      // this.loading = false
      // return
      // let item = this.$refs['swiper-cert'].$swiper
      let item = this.$refs['swiper-cert'].$swiper
      // console.log('offsetSlide OUT', item)
      // if (item) {
      // console.log('offsetSlide IN', item)
      console.log('offsetSlide IN')
      // let el = document.getElementsByClassName('swiper-wrapper')[0]
      let translateX = item.translate
      const offset = translateX + 35
      // this.$nextTick(() => {
      console.log('move ', translateX, offset)
      setTimeout(() => {
        console.log('get ', item.getTranslate())
        // this.swiper.setTranslate(`translate3d(${offset}px, 0px, 0px)`)
        // el.style.transform = `translate3d(${offset}px, 0px, 0px)`
        item.setTranslate(offset)
        // this.swiper.translateTo(offset, 110, false, true)
        console.log('get ', item.getTranslate())
        // setTimeout(() => {
        //   if (isLoading !== null) {
        //     this.loading = isLoading
        //   }
        // })
      }, 35)
      // setTimeout(() => {
      if (isLoading !== null) {
        this.loading = isLoading
      }
      // }, 250)
      // })
      // }
      // return Promise.resolve()
    }
  },
  mounted() {
    setTimeout(() => {
      this.init()
    }, 1850)
  },
  computed: {
    ...mapState({
      designs: state => state?.app?.pageOrderConfig?.Design,
      // options: state => state.certificate.options,
      currentCertificate: state => state.basket.currentCertificate,
      isMobile: state => state.app.isMobile,
      preview: state => state.basket.preview
    }),
    options() {
      let result = {
        certificates: []
      }
      const mainSlide = this.designs?.find(x => x.Main === true)
      const otherSlides = this.designs
        ?.filter(x => x.Main !== true)
        .map(x => {
          return {
            id: x.Id,
            img: x.UrlImage
          }
        })

      if (mainSlide) {
        result.certificates.push({
          id: mainSlide?.Id,
          img: mainSlide?.UrlImage
        })
      }
      if (otherSlides?.length) {
        result.certificates = result.certificates.concat(otherSlides)
      }
      return result
    },
    swiperOption() {
      return swiperOption
    },
    swiper() {
      return this.$refs['swiper-cert'].$swiper
    },
    countCertificates() {
      const count = this.options.certificates.length
      return count ? count : 0
    }
  }
}
</script>
